import uiReducer from './uiReducer';
import sidebarReducer from './sidebarReducer';
import rtlReducer from './rtlReducer';
import documentsReducer from './documentsReducer';
import lookupsReducer from './lookupsReducer';
import clientsReducer from './clientsReducer';
import errorsReducer from './errorsReducer';
import messagesReducer from './messagesReducer';
import authReducer from './authReducer';
import usersReducer from './usersReducer';
import itemsReducer from './itemsReducer';
import currenciesReducer from './currenciesReducer';
import exchangeRatesReducer from './exchangeRatesReducer';
import chartOfAccountsReducer from './chartOfAccountsReducer';
import journalsReducer from './journalsReducer';
import bankingReducer from './bankingReducer';
import customerReducer from './customerReducer';
import salesAccountsReducer from './salesAccountsReducer';
import salesContactsReducer from './salesContactsReducer';
import suppliersReducer from './suppliersReducer';
import supplierContactsReducer from './supplierContactsReducer';
import quotationsReducer from './quotationsReducer';
import proInvoicesReducer from './proInvoicesReducer';
import invoicesReducer from './invoicesReducer';
import paymentsReceivedReducer from './paymentsReceivedReducer';
import creditNotesReducer from './creditNotesReducer';
import purOrdersReducer from './purOrdersReducer';
import paymentTermsReducer from './paymentTermsReducer';
import paymentModesReducer from './paymentModesReducer';
import salesPersonsReducer from './salesPersonsReducer';
import expensesReducer from './expensesReducer';
import taxRatesReducer from './taxRatesReducer';
import billsReducer from './billsReducer';
import paymentsMadeReducer from './paymentsMadeReducer';
import supplierCreditsReducer from './supplierCreditsReducer';
import currencyAdjustmentReducer from './currencyAdjustmentReducer';
import leavesReducer from './leavesReducer';
import salaryReducer from './salaryReducer';
import companyFilesReducer from './companyFilesReducer';
import userFilesReducer from './userFilesReducer';
import assetsLogReducer from './assetsLogReducer';
import docsRequestReducer from './docsRequestReducer';
import companyProfileReducer from './companyProfileReducer';
import storageReducer from './storageReducer';
import finalPayReducer from './finalPayReducer';
import companyHolidaysReducer from './companyHolidaysReducer';
import internalMeetingsReducer from './internalMeetingsReducer';
import trashReducer from './trashReducer';
import transactionsLockingReduer from './transactionsLockingReduer';
import taxReturnReducer from './taxReturnReducer';
import reportsReducer from './reportsReducer';
import dashboardReducer from './dashboardReducer';
import operationsReducer from './operationsReducer';

export {
  uiReducer,
  sidebarReducer,
  rtlReducer,
  documentsReducer,
  lookupsReducer,
  clientsReducer,
  errorsReducer,
  messagesReducer,
  authReducer,
  usersReducer,
  leavesReducer,
  docsRequestReducer,
  salaryReducer,
  companyFilesReducer,
  companyProfileReducer,
  userFilesReducer,
  assetsLogReducer,
  finalPayReducer,
  itemsReducer,
  currenciesReducer,
  exchangeRatesReducer,
  chartOfAccountsReducer,
  journalsReducer,
  bankingReducer,
  customerReducer,
  salesAccountsReducer,
  salesContactsReducer,
  suppliersReducer,
  supplierContactsReducer,
  quotationsReducer,
  proInvoicesReducer,
  invoicesReducer,
  paymentsReceivedReducer,
  creditNotesReducer,
  purOrdersReducer,
  paymentTermsReducer,
  paymentModesReducer,
  salesPersonsReducer,
  expensesReducer,
  taxRatesReducer,
  billsReducer,
  paymentsMadeReducer,
  supplierCreditsReducer,
  currencyAdjustmentReducer,
  storageReducer,
  companyHolidaysReducer,
  internalMeetingsReducer,
  trashReducer,
  transactionsLockingReduer,
  taxReturnReducer,
  reportsReducer,
  dashboardReducer,
  operationsReducer,
};
